import React, { useContext } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import Logo from "../Logo";

const Footer = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      <div className="footer-section bg-default-4 py-9 ">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="3" md="4">
              <div className="footer-logo text-center text-md-left mb-7 mb-md-0">
                <Logo
                  white={gContext.footer.theme === "dark"}
                  className="mx-auto mx-md-0"
                />
              </div>
            </Col>
            
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
