exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-b-2-b-js": () => import("./../../../src/pages/b2b.js" /* webpackChunkName: "component---src-pages-b-2-b-js" */),
  "component---src-pages-berries-js": () => import("./../../../src/pages/berries.js" /* webpackChunkName: "component---src-pages-berries-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-co-working-js": () => import("./../../../src/pages/co-working.js" /* webpackChunkName: "component---src-pages-co-working-js" */),
  "component---src-pages-consultation-js": () => import("./../../../src/pages/consultation.js" /* webpackChunkName: "component---src-pages-consultation-js" */),
  "component---src-pages-contact-1-js": () => import("./../../../src/pages/contact1.js" /* webpackChunkName: "component---src-pages-contact-1-js" */),
  "component---src-pages-contact-2-js": () => import("./../../../src/pages/contact2.js" /* webpackChunkName: "component---src-pages-contact-2-js" */),
  "component---src-pages-contact-3-js": () => import("./../../../src/pages/contact3.js" /* webpackChunkName: "component---src-pages-contact-3-js" */),
  "component---src-pages-ecommerce-js": () => import("./../../../src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-fruits-js": () => import("./../../../src/pages/fruits.js" /* webpackChunkName: "component---src-pages-fruits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-details-js": () => import("./../../../src/pages/job-details.js" /* webpackChunkName: "component---src-pages-job-details-js" */),
  "component---src-pages-job-directory-js": () => import("./../../../src/pages/job-directory.js" /* webpackChunkName: "component---src-pages-job-directory-js" */),
  "component---src-pages-job-openings-js": () => import("./../../../src/pages/job-openings.js" /* webpackChunkName: "component---src-pages-job-openings-js" */),
  "component---src-pages-mobile-app-js": () => import("./../../../src/pages/mobile-app.js" /* webpackChunkName: "component---src-pages-mobile-app-js" */),
  "component---src-pages-mushrooms-js": () => import("./../../../src/pages/mushrooms.js" /* webpackChunkName: "component---src-pages-mushrooms-js" */),
  "component---src-pages-powders-js": () => import("./../../../src/pages/powders.js" /* webpackChunkName: "component---src-pages-powders-js" */),
  "component---src-pages-pricing-1-js": () => import("./../../../src/pages/pricing1.js" /* webpackChunkName: "component---src-pages-pricing-1-js" */),
  "component---src-pages-pricing-2-js": () => import("./../../../src/pages/pricing2.js" /* webpackChunkName: "component---src-pages-pricing-2-js" */),
  "component---src-pages-pricing-3-js": () => import("./../../../src/pages/pricing3.js" /* webpackChunkName: "component---src-pages-pricing-3-js" */),
  "component---src-pages-product-details-js": () => import("./../../../src/pages/product-details.js" /* webpackChunkName: "component---src-pages-product-details-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-reset-pass-js": () => import("./../../../src/pages/reset-pass.js" /* webpackChunkName: "component---src-pages-reset-pass-js" */),
  "component---src-pages-saas-subscription-js": () => import("./../../../src/pages/saas-subscription.js" /* webpackChunkName: "component---src-pages-saas-subscription-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-telemedicine-js": () => import("./../../../src/pages/telemedicine.js" /* webpackChunkName: "component---src-pages-telemedicine-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-vegetables-js": () => import("./../../../src/pages/vegetables.js" /* webpackChunkName: "component---src-pages-vegetables-js" */),
  "component---src-pages-video-conference-js": () => import("./../../../src/pages/video-conference.js" /* webpackChunkName: "component---src-pages-video-conference-js" */),
  "component---src-pages-web-application-js": () => import("./../../../src/pages/web-application.js" /* webpackChunkName: "component---src-pages-web-application-js" */)
}

