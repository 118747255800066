export const menuItems = [
  {
    name: "#",
    label: "Home",
  },
  {
    name: "products",
    label: "Products",
    items: [
      { name: "berries", label: "Frozen berries" },
      { name: "fruits", label: "Frozen fruits" },
      { name: "vegetables", label: "Frozen vegetables" },
      { name: "mushrooms", label: "Frozen mushrooms" },
      { name: "powders", label: "Milk, Whey and Sugar" },
    ]
  },
  {
    name: "contact3",
    label: "Contact",
    //isExternal: true,
  },
];
